import React from "react"
import firebase from "gatsby-plugin-firebase"
import LeftSideComponent from '../LeftSideComponent/LeftSideComponent'
import RightSideComponent from "../RightSideComponent/RightSideComponent"
import Form from "./Form"
import LockSvg from '../../../assets/svg/lock.svg'
import AuthMessage from '../AuthMessage/AuthMessage'
import * as styles from './resetpassword.module.scss'

export default ({actionCode}) => {
  const [email, setEmail] = React.useState(null)
  const [finished, setFinished] = React.useState(false)

  async function setNewPassword(values){
      let auth = firebase.auth()
      auth.confirmPasswordReset(actionCode, values.passwordOne).then(function(resp) {
          setFinished(true)
      }).catch((error) => {

      })
  }
  // React.useEffect(() => {
  //     let auth = firebase.auth()
  //     auth.verifyPasswordResetCode(actionCode).then(function(email) {
  //         setEmail(email)
  //     }).catch((error) => {

  //     })
  // }, [])
  return (
    finished ? 
    <AuthMessage icon={<LockSvg />} title={'Password has been reset'} />:
    <div className={styles.container}>
      <LeftSideComponent>
        <div className={styles.inner}>
        <h2>Password reset</h2>
            <Form setNewPassword={setNewPassword} />
        </div>
      </LeftSideComponent>
      <RightSideComponent />
    </div>
  )
}