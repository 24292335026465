import React from "react"
import VerifyEmail from '../components/Authentication/VerifyEmail/VerifyEmail'
import ResetPassword from "../components/Authentication/ResetPassword/ResetPassword"

export default () => {
  const [type, setType] = React.useState({})
  React.useEffect(() => {
    var searchParams = new URLSearchParams(window.location.search)

    // Get the action to complete.
    var mode = searchParams.get("mode")
    // Get the one-time code from the query parameter.
    var actionCode = searchParams.get("oobCode")

    // Handle the user management action.
    setType({ mode, actionCode })
  }, [])

  return type.mode === "verifyEmail" ? (
    <VerifyEmail actionCode={type.actionCode} />
  ) : type.mode === "resetPassword" ? (
    <ResetPassword actionCode={type.actionCode} />
  ) : null
}
