import React from 'react'
import firebase from "gatsby-plugin-firebase"
import MailSvg from '../../../assets/svg/email.svg'
import AuthMessage from '../AuthMessage/AuthMessage'

export default ({actionCode}) => {
    const [verified, setVerified] = React.useState(null)

    React.useEffect(() => {
            let auth = firebase.auth()
            auth.applyActionCode(actionCode).then(async (resp) => {
                if(auth.currentUser) await auth.currentUser.reload().catch(err => {
                    // Handle error
                })
                setVerified(true)
            }).catch(function(error) {
                setVerified(false)
            });
    }, [])

    return(verified === null ?
        null :
        <AuthMessage icon={<MailSvg />} title={verified ? `Your e-mail has been verified` : `Unable to verify your email address`}
        text={verified ? null : 'Your link has expired. Re-send the verification link from the account page.'}/>
    )
}