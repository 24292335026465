import React from "react"
import * as Yup from 'yup'
import Form from '../../Common/Form/Form'

export default ({setNewPassword}) => {
  const validationSchema = Yup.object().shape({
    passwordOne: Yup.string()
    .min(8, 'Password is too short.')
    .required('Password is required.'),
    passwordTwo: Yup.string().oneOf([Yup.ref('passwordOne'), null], 'Passwords do not match.')
  });

  return (
    <>
    <Form onSubmit={setNewPassword} submitButtonText={'Set Password'} validationSchema={validationSchema} initialValues={{
        passwordOne: "",
        passwordTwo: "",
        }} fields={[
            {
                placeholder: 'Enter your new password',
                label: "New Password",
                type: 'password',
                name: 'passwordOne',
            },
            {
                placeholder: 'Enter your new password',
                label: "Retype your new Password",
                type: 'password',
                name: 'passwordTwo',
            },
        ]} />
    </>
  )
}